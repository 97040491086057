import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BgImg from "gatsby-background-image"
import NavigationTile from "../navigation-tile/NavigationTile"
import * as styles from "./hero.module.scss"

const Hero = ({ banner, whereWeServiceActive, whereWeServiceSlug }) => {
  const { mobile, desktop, heading, subheading, buttons, background_color } =
    banner
  const data = useStaticQuery(getData)
  const { primary_logo } = data.kontentItemSettings.elements

  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const getNavTiles = () => {
    if (data.allKontentItemNavigationBlock.nodes[0].elements.navigation_tiles) {
      // Navigation tiles are a specific type of content block that is returned by Content blocks.
      return (
        <div className="nav-tile-wrapper">
          {data.allKontentItemNavigationBlock.nodes[0].elements.navigation_tiles.value.map(
            ({ id, elements }) => {
              return (
                <NavigationTile
                  key={id}
                  tiles={elements}
                  whereWeServiceSlug={whereWeServiceSlug}
                  whereWeServiceActive={whereWeServiceActive}
                />
              )
            }
          )}
        </div>
      )
    }
  }

  return (
    <BgImg
      fluid={sources}
      alt={desktop.value[0].description}
      className={styles.hero}
      preserveStackingContext={true}
    >

      <section className={`container ${styles.bannerContent}`}>
        <img
          src={primary_logo.value[0].url}
          alt={primary_logo.value[0].description}
          className={styles.logo}
        />

        <h1>{heading.value}</h1>
        <RichTextElement value={subheading.value} className />
        <div className={styles.homePageBannerLinks}>
          {buttons.value.map((button, i) => {
            const { button_text, linked_page } = button.elements
            const { slug } = linked_page.value[0].elements
            let className = "primary-button"
            if (buttons.value.length === 1)
              className = `primary-button ${styles.singleButton}`
            if (i === 1) className = "white-accent-ghost-button"

            return (
              <Link key={button.id} to={slug.value} className={className}>
                {button_text.value}
              </Link>
            )
          })}
        </div>
        {getNavTiles()}
      </section>
    </BgImg>
  )
}

const getData = graphql`
  query getStuff {
    kontentItemSettings {
      elements {
        primary_logo {
          value {
            description
            url
          }
        }
      }
    }
    allKontentItemNavigationBlock {
      nodes {
        elements {
          navigation_tiles {
            value {
              ... on kontent_item_navigation_tile {
                id
                elements {
                  background_image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  cta {
                    value
                  }
                  description {
                    value
                  }
                  heading {
                    value
                  }
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  internal_link {
                    value {
                      ... on kontent_item_page {
                        id
                        elements {
                          slug {
                            value
                          }
                        }
                      }
                      ... on kontent_item_service_area {
                        id
                        elements {
                          slug {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Hero
