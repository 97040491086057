import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import * as styles from "./navigation-tile.module.scss"
const NavigationTile = ({
  tiles,
  whereWeServiceSlug,
  whereWeServiceActive,
}) => {
  const { background_image, cta, heading, internal_link } = tiles
  const { __typename } = internal_link.value[0]
  const { slug } = internal_link.value[0].elements
  let newSlug = slug.value
  if (
    __typename === "kontent_item_service_area" &&
    whereWeServiceActive === "true"
  ) {
    newSlug = `/${whereWeServiceSlug}/${slug.value}`
  }

  return (
    <Link to={newSlug} className={styles.navTileLink}>
      <div className={styles.shadow}></div>
      <div className={styles.navTileInner}>
        <h3 className="nav-tile-heading">{heading.value}</h3>
        <p className={styles.navTileCta}>{cta.value}</p>
      </div>
      <Img fluid={background_image.value[0].fluid} alt={background_image.value[0].description} className={styles.navTile} />
    </Link>
  )
}

export default NavigationTile
